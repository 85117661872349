import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

const Header = () => {
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = function () { scrollFunction() };
    }
  }, [])

  const scrollFunction = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }
  return (
    <header className={`site-header ${scrolled ? 'compact' : ''}`}>
      <h1>LedMebel</h1>
      <ul>
        <li>
          <Link to="/#our-products">Продукты</Link>
        </li>
        <li>
          <Link to="/#why-us">Почему мы</Link>
        </li>
        <li>
          <Link to="/#contact">Контакт</Link>
        </li>
      </ul>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
