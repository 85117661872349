import React from "react"
import VKicon from "../assets/icons/vk.inline.svg"
import WhatsUpIcon from "../assets/icons/whatsup.inline.svg"
import FacebookIcon from "../assets/icons/facebook.inline.svg"
import InstagramIcon from "../assets/icons/instagram.inline.svg"

const Footer = () => {
  return (
    <footer>
      <div className="contact-info">
        <h1>LedMebel</h1>
        <a href="tel:+7 (909) 022-34-44">
          +7 (909) 022-34-44 <br />
          (бесплатный звонок по РФ)
        </a>
        <a href="mailto:ledmebel96@yandex.ru?subject=Вопрос ledmebel.ru&">
          ledmebel96@yandex.ru
        </a>
      </div>
      <div className="social-info">
        <a
          href="https://www.instagram.com/ledmebel/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://www.facebook.com/ledholiday/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://vk.com/ledmebelclub"
          target="_blank"
          rel="noopener noreferrer"
        >
          <VKicon />
        </a>
        <a href="https://wa.me/79090165577" rel="noopener noreferrer">
          <WhatsUpIcon />
        </a>
      </div>
    </footer>
  )
}

export default Footer
